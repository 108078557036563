import './page-header.js';
import './user-agent.js';
import './delighters.js';

// for delighters
options = {
	attribute: 'data-delighter',
	classNames: ['delighter', 'started', 'ended'],
	start: 0.75, // default start threshold
	end: 0.75, // default end threshold
	autoInit: true // initialize when DOMContentLoaded
};
