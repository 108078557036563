// via. https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
var prevScrollPos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollPos > currentScrollPos) {
    document.getElementById('page-header').style.top = '0';
  } else {
    document.getElementById('page-header').style.top = '-96px';
  }
  prevScrollPos = currentScrollPos;
};
